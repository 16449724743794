import { PrimeIcons } from 'primeng/api';
import { CustomMenuItem } from '../models/custom-menu-item.model';
import { ORG_FEATURES_SECTIONS } from '../models/user.model';

export const NAME_PLACEHOLDER = '<NAME>';

export const MENU_ITEMS: CustomMenuItem[] = [
  {
    label: 'private.sidebarItems.agents',
    icon: 'chat_bubble',
    iconType: 'material',
    routerLink: ['/private', 'assistants-list'],
    data: {
      level: 100,
    },
  },
  {
    label: 'private.sidebarItems.gallery',
    icon: 'image',
    iconType: 'material',
    routerLink: ['/private', 'gallery'],
    data: {
      level: 100,
      settingKey: ORG_FEATURES_SECTIONS.IMAGE_GENERATION,
    },
  },
  {
    label: 'private.sidebarItems.marketplace',
    icon: 'local_mall',
    iconType: 'material',
    routerLink: ['/private', 'marketplace'],
    data: {
      level: 100,
      settingKey: ORG_FEATURES_SECTIONS.MARKETPLACE,
    },
  },
  {
    label: 'private.sidebarItems.agency',
    icon: 'warehouse',
    iconType: 'material',
    routerLink: ['/private', 'agency'],
    data: {
      level: 600,
      hideIfNotGlueLabs: true,
    },
  },
  {
    label: 'private.sidebarItems.pipelines',
    icon: 'valve',
    iconType: 'material',
    routerLink: ['/private', 'pipelines'],
    data: {
      level: 100,
      hideIfNotGlueLabs: true,
    },
  },
  {
    isBottomOfMenu: true,
    label: 'private.sidebarItems.faq',
    icon: 'help',
    iconType: 'material',
    routerLink: ['/private', 'faq'],
    data: {
      level: 100,
      hideIfNotGlueLabs: true,
    },
  },
  {
    isBottomOfMenu: true,
    label: NAME_PLACEHOLDER,
    routerLink: ['/private', 'account'],
    data: {
      level: 100,
      isProfile: true,
    },
  },
];
