{
  "name": "pupau-chatbot",
  "version": "0.86.2",
  "scripts": {
    "ng": "ng",
    "start": "node ./scripts/photo-editor-polyfill.js && ng serve --open",
    "start:prod": "node ./scripts/photo-editor-polyfill.js && ng serve --configuration production --open",
    "start:prod-in-dev": "node ./scripts/photo-editor-polyfill.js && ng serve --configuration prodInDev --open",
    "build": "node ./scripts/photo-editor-polyfill.js && ng build",
    "build:development": "node ./scripts/photo-editor-polyfill.js && ng build --configuration development",
    "build:production": "node ./scripts/photo-editor-polyfill.js && ng build --configuration production",
    "test": "ng test",
    "sync": "git pull git@bitbucket.org:gluelabs/ngx-primeng-glue.git",
    "compodoc:build": "compodoc -p tsconfig.doc.json",
    "compodoc:build-and-serve": "compodoc -p tsconfig.doc.json -s -w",
    "compodoc:serve": "compodoc -s",
    "deploy-prod-in-staging": "npm version patch && git push && npm run build:development && firebase deploy --only hosting:staging",
    "deploy-patch:staging": "npm version patch && git push && npm run build:development && firebase deploy --only hosting:staging",
    "deploy-patch:prod": "npm version patch && git push && npm run build:production && firebase deploy --only hosting:prod",
    "deploy-patch:all": "npm version patch && git push && npm run build:development && firebase deploy --only hosting:staging && npm run build:production && firebase deploy --only hosting:prod",
    "deploy-minor:staging": "npm version minor && git push && npm run build:development && firebase deploy --only hosting:staging",
    "deploy-minor:prod": "npm version minor && git push && npm run build:production && firebase deploy --only hosting:prod",
    "deploy-minor:all": "npm version minor && git push && npm run build:development && firebase deploy --only hosting:staging && npm run build:production && firebase deploy --only hosting:prod",
    "deploy-major:staging": "npm version major && git push && npm run build:development && firebase deploy --only hosting:staging",
    "deploy-major:prod": "npm version major && git push && npm run build:production && firebase deploy --only hosting:prod",
    "deploy-major:all": "npm version major && git push && npm run build:development && firebase deploy --only hosting:staging && npm run build:production && firebase deploy --only hosting:prod"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.10",
    "@angular/pwa": "^17.3.10",
    "@angular/cdk": "^16.2.8",
    "@angular/common": "^17.3.10",
    "@angular/compiler": "^17.3.10",
    "@angular/core": "^17.3.10",
    "@angular/fire": "^17.1.0",
    "@angular/forms": "^17.3.10",
    "@angular/material": "^16.2.8",
    "@angular/platform-browser": "^17.3.10",
    "@angular/platform-browser-dynamic": "^17.3.10",
    "@angular/router": "^17.3.10",
    "@angular/service-worker": "^17.3.10",
    "@fullcalendar/angular": "^6.0.3",
    "@fullcalendar/core": "^6.0.3",
    "@fullcalendar/daygrid": "^6.0.3",
    "@fullcalendar/interaction": "^6.0.3",
    "@fullcalendar/timegrid": "^6.0.3",
    "@ngrx/effects": "^17.2.0",
    "@ngrx/router-store": "^17.2.0",
    "@ngrx/store": "^17.2.0",
    "@ngrx/store-devtools": "^17.2.0",
    "@ngx-translate/core": "^14.0.0",
    "@ngx-translate/http-loader": "^7.0.0",
    "ace-builds": "^1.35.2",
    "ang-jsoneditor": "^3.1.1",
    "angular-markdown-editor": "^3.1.0",
    "animejs": "^3.2.1",
    "bootstrap": "^5.3.3",
    "chart.js": "^4.3.0",
    "chartjs-plugin-annotation": "^3.0.1",
    "cropperjs": "^1.6.2",
    "file-saver": "^2.0.5",
    "firebase": "^10.13.1",
    "font-awesome": "^4.7.0",
    "lodash": "^4.17.21",
    "luxon": "^3.4.4",
    "markdown-it": "^14.0.0",
    "markdown-it-for-inline": "^2.0.1",
    "ng-lazyload-image": "^9.1.3",
    "ngrx-store-localstorage": "^17.0.0",
    "ngx-file-drag-drop": "^9.0.0",
    "ngx-infinite-scroll": "^17.0.0",
    "ngx-markdown": "^17.2.1",
    "ngx-markdown-editor": "^5.3.4",
    "ngx-photo-editor": "^0.4.4",
    "primeflex": "^3.3.1",
    "primeicons": "7.0.0",
    "primeng": "17.17.0",
    "quill": "^2.0.2",
    "rxjs": "~7.8.0",
    "sse.js": "^2.5.0",
    "tslib": "^2.3.0",
    "uuid": "^9.0.1",
    "zone.js": "^0.14.6"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular-devkit/schematics": "^17.3.8",
    "@angular/cli": "~17.3.8",
    "@angular/compiler-cli": "^17.3.10",
    "@angular/localize": "^17.3.10",
    "@babel/plugin-proposal-private-methods": "^7.18.6",
    "@compodoc/compodoc": "^1.1.12",
    "@types/animejs": "^3.1.8",
    "@types/chart.js": "^2.9.41",
    "@types/file-saver": "^2.0.7",
    "@types/gtag.js": "^0.0.18",
    "@types/jasmine": "~4.3.0",
    "@types/lodash": "^4.14.194",
    "@types/luxon": "^3.4.0",
    "@types/markdown-it": "^13.0.7",
    "@types/uuid": "^9.0.8",
    "jasmine-core": "~4.5.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.0.0",
    "typescript": "~5.2"
  }
}
